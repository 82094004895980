<template>
  <b-form-group>
    <label :for="id" v-if="withLabel">{{ $t(id) }}</label>
    <b-form-textarea
      :type="type"
      :id="id"
      :name="id"
      :value="value"
      @input="(val) => $emit('input', val)"
      :placeholder="$t(id)"
      :rows="rows"
    ></b-form-textarea>
  </b-form-group>
</template>

<script>
export default {
  name: "NormalTextArea",
  props: {
    id: { required: true, type: String },
    value: { required: false, type: String, default: "" },
    type: { required: false, type: String, default: "text" },
    rows: { required: false, type: Number, default: 4 },
    prename: { required: false, type: String, default: "" },
    withLabel: { required: false, type: Boolean, default: true },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>