<template>
  <b-form-group>
    <label :for="id" v-if="withLabel">{{ $t(id) }}</label>
    <b-input-group class="mt-3">
      <template #prepend v-if="icon != null">
        <b-input-group-text
          ><strong :class="iconColor"
            ><i :class="icon" v-if="icon != null" /></strong
        ></b-input-group-text>
      </template>
      <b-form-input
        :type="type"
        :id="id"
        :name="id"
        :value="value"
        @input="(val) => $emit('input', val)"
        :placeholder="$t(id)"
        @keypress="onKeyPress"
      ></b-form-input>
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: "NormalTextBox",
  props: {
    id: { required: true, type: String },
    value: { required: false, type: String, default: "" },
    type: { required: false, type: String, default: "text" },
    prename: { required: false, type: String, default: "" },
    withLabel: { required: false, type: Boolean, default: true },
    icon: { type: String, default: null },
    iconColor: { type: String, default: "text-dark" },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    onKeyPress(event) {
      this.$emit("keypress", event);
    },
  },
};
</script>