<template>
  <div class="animated">
    <b-card no-body>
      <b-card-header>
        <i class="icon-menu mr-1"></i>
        {{ $t("newsPositions") }}
      </b-card-header>
      <b-card-body class="pl-3 pb-3">
        <b-row>
          <b-col sm="4">
            <label for="positionList">{{ $t("newsPositions") }}</label>
            <CustomDropDown
              id="positionList"
              displayField="entityName"
              valueField="id"
              :firstText="$t('all')"
              :selectedValue="null"
              :filter="
                (p) => p.id != 19 && p.id != 41 && p.id != 50 && p.id != 51
              "
              :orderBy="positionOrderBy()"
              @change="(val) => positionChanged(val)"
              apiSource="Entities/getpositionentities"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body v-if="newsList.length > 0">
      <b-card-header>
        <i class="icon-menu mr-1"></i>
        {{ $t("newsList") }}
        <div class="card-header-actions">
          <BrandButton
            :text="$t('save')"
            @click="save()"
            v-if="this.newsList.length > 0 && $auth.hasAuth($options, 'save')"
            buttonIcon="fa fa-save"
            buttonClass="mr-1 btn-brand btn-success"
          ></BrandButton>
        </div>
      </b-card-header>
      <b-card-body class="p-3">
        <draggable
          v-model="newsList"
          id="content"
          class="row"
          v-if="$auth.hasAuth($options, 'list')"
          handle=".handle"
          :move="checkMove"
          @end="onEnd"
          @start="onStart"
        >
          <b-col
            sm="2"
            v-bind:key="news.news.id"
            v-for="news in newsList"
            :class="news.isLocked ? 'un-handle' : 'handle m-0'"
          >
            <b-row class="p-0">
              <b-col sm="12">
                <div class="card bg-light">
                  <label class="index">{{ news.order }}</label>
                  <CustomCheckBox
                    id="isLocked"
                    v-model="news.isLocked"
                    @input="(val) => (news.isLocked = val)"
                    size="sm"
                    :defaultValue="news.isLocked"
                    :labelIcon="{
                      dataOn: $t('on'),
                      dataOff: $t('off'),
                    }"
                  />
                  <b-img
                    class="card-img-top"
                    :src="
                      news.news.thumbnail == null
                        ? '/img/noPic.png'
                        : news.news.thumbnail
                    "
                  />
                  <div class="card-body">
                    <div class="card-title h6 text-center">
                      {{ news.news.title }}
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </draggable>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import BrandButton from "../../widgets/form/CustomBrandButton";
import NormalTextBox from "../../widgets/form/NormalTextBox";
import NormalTextArea from "../../widgets/form/NormalTextArea";
import CustomDropDown from "../../widgets/form/CustomDropDown";
import draggable from "vuedraggable";
import http from "../../shared/http";
import toastHelper from "../../shared/toast-helper";

import CustomCheckBox from "../../widgets/form/CustomCheckBox";
import { parse } from "@babel/core";
export default {
  name: "NewsOrders",
  components: {
    BrandButton,
    draggable,
    NormalTextBox,
    NormalTextArea,
    CustomDropDown,
    CustomCheckBox,
  },
  data() {
    return {
      positionActiveNewCount: 0,
      selectedPositionId: 0,
      newsList: [],
      positions: [],
      oldNewsList: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    checkMove: function (evt) {
      return !evt.relatedContext.element.isLocked;
    },
    onStart(evt) {
      this.oldNewsList = [];
      const items = this.newsList.filter((f) => f.isLocked);
      if (items && items.length) {
        items.forEach((el) => {
          this.oldNewsList.push({ id: el.newsId, order: el.order });
        });
      }
    },
    onEnd(evt) {
      let list = [];
      const lockedItems = this.oldNewsList;
      let notLockedNews = [];
      let notLocked = this.newsList.filter((n) => {
        return !lockedItems.some((f) => f.id === n.newsId);
      });

      let locked = this.newsList.filter((n) => {
        return lockedItems.some((f) => f.id === n.newsId);
      });

      if (notLocked && notLocked.length) {
        for (let index = 0; index < notLocked.length; index++) {
          let element = notLocked[index];
          notLockedNews.push({ id: element.newsId });
        }
      }
      if (notLockedNews && notLockedNews.length) {
        for (let index = 0; index < notLockedNews.length; index++) {
          let element = notLockedNews[index];
          let news = this.newsList.filter((f) => f.newsId === element.id)[0];
          var order = index + 1;
          while (list.some((f) => f.order === order) || locked.some((f) => f.order === order)) {
            order++;
          }
          news.order = order;
          list.push(news);
        }
      }
      if (locked && locked.length) {
        locked = locked.sort((a, b) => a.order - b.order);
        for (let index = 0; index < locked.length; index++) {
          let element = locked[index];
          //list[element.order] = element;
          list.splice(element.order, 0, element);
          // let news = list.filter((f) => f.order === element.order);
          // if (news && news[0] && news[0].newsId !== element.id) {
          //   let order = element.order + 1;
          //   while (list.some((f) => f.order === order && f.isLocked)) {
          //     order++;
          //   }
          //   news[0].order = order;
          // }
        }
      }

      this.newsList = list;

      this.newsList = this.newsList.sort((a, b) => b.isLocked - a.isLocked);
      this.newsList = this.newsList.sort((a, b) => a.order - b.order);
      // if (this.newsList.length) {
      //   for (let i = 0; i < this.newsList.length; i++) {
      //     this.newsList[i].order = i + 1;
      //     if (this.newsList[i].order <= this.positionActiveNewCount) {
      //       this.newsList[i].cardClass = "card activeNews";
      //     } else {
      //       this.newsList[i].cardClass = "card bg-light";
      //     }
      //   }
      // }
    },
    save() {
      let model = [];
      this.newsList.forEach((news) => {
        model.push({
          newsId: news.news.id,
          positionEntityId: parseInt(this.selectedPositionId),
          order: news.order,
          isLocked: news.isLocked,
        });
      });
      http.post(`News/updatenewspositionorders`, model).then((response) => {
        if (response.success) {
          toastHelper.success(response.message);
        } else {
          toastHelper.error(response.message);
        }
      });
    },
    positionChanged(positionId) {
      if (positionId != null) {
        this.selectedPositionId = positionId.id;
        this.positionActiveNewCount = this.$appSetting.positionEntity.filter(
          (p) => p.id == positionId.id
        )[0].activeNewsCount;
        http
          .get(
            `News/getnewspositionorders?newsPositionEntity=${positionId.id}&limit=${this.positionActiveNewCount}`
          )
          .then((response) => {
            for (let i = 0; i < response.length; i++) {
              response[i].order = i + 1;
              response[i].cardClass = "card bg-light";
            }
            this.newsList = response;
            //this.oldNewsList = this.newsList;
          });
      }
    },
    positionOrderBy() {
      return (orderItem) =>
        this.$appSetting.MainPositions.findIndex((p) => p.id == orderItem.id) *
        -1;
    },
    getList() {
      http.get(`Entities/getpositionentities`).then((response) => {
        let list = [];

        response.forEach((e) => {
          var exists = this.$appSetting.positionEntity.filter(
            (u) => u.activeNewsCount > 0 && u.id === e.id
          );
          if (exists && exists.length > 0) list.push(e);
        });

        for (let i = 0; i < list.length; i++) {
          list[i].order =
            this.$appSetting.MainPositions.findIndex(
              (p) => p.id == list[i].id
            ) * -1;
        }
        //list.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
        this.positions = list.sort((p) => p.order);
      });
    },
  },
};
</script>

<style scoped>
.card-body {
  padding-top: 8px;
  padding-bottom: 0px;
}
.previewimage {
  cursor: pointer;
}
.animated .fadeIn .row div:first-of-type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.card-body {
  padding-left: 1px;
  padding-right: 1px;
}
.card-title fieldset {
  width: 100%;
  margin-bottom: 0;
}
.card-title {
  width: 100%;
  margin-bottom: 5;
}
.card-text {
  width: 100%;
}
.card-text fieldset {
  margin-bottom: 5px;
}
.index {
  background-color: white;
  border-radius: 30px;
  height: 30px;
  width: 30px;
  position: absolute;
  top: 5px;
  right: 5px;
  text-align: center;
  padding-top: 3px;
  border-color: black;
  border: 1px;
  border-style: solid;
}
.card-img-top {
  width: 100%;
  min-width: revert;
  object-fit: cover;
  cursor: pointer;
}
.form-group {
  margin: 5px;
}
.card.bg-light {
  box-shadow: 2px 2px 2px silver;
}
</style>